import { http } from '@/http/axios.js'
import { http_limit } from '@/http/axios_limit.js'
import axios from 'axios'
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/roster/black/page',
    method: 'get',
    params
  })
}
// 新增
export function addRosterAPI(data) {
  return http({
    url: '/admin/roster/black/add',
    method: 'post',
    data
  })
}
// 编辑
export function editRosterAPI(data) {
  return http({
    url: '/admin/roster/black/edit',
    method: 'post',
    data
  })
}
// 详情
export function getRosterDetailAPI(params) {
  return http({
    url: '/admin/roster/black/detail',
    method: 'get',
    params
  })
}
// 操作记录
export function getRecordAPI(rosterId) {
  return http({
    url: '/admin/roster/black/action/list',
    method: 'get',
    params: { rosterId }
  })
}
// 移库
export function handleBlackMoveAPI(data) {
  return http({
    url: '/admin/roster/black/move',
    method: 'post',
    data
  })
}
// 上传
export function handleUploadAPI(data) {
  return http_limit({
    url: '/admin/roster/black/import',
    method: 'post',
    data
  })
}
export function downloadFile(pdfUrl, name) {
  axios({
    url: pdfUrl,
    method: 'GET',
    responseType: 'blob' // 必须指定为blob类型才能下载
  }).then(res => {
    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  })
}
